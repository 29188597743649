import { enableProdMode, importProvidersFrom } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NotificationModule } from '@progress/kendo-angular-notification';
import {ICON_SETTINGS, IconModule} from '@progress/kendo-angular-icons';
import { PopupModule } from '@progress/kendo-angular-popup';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from './environments/environment';
import { SharedModule } from './app/shared/shared.module';
import { AppRoutingModule } from './app/app-routing.module';
import { CoreModule } from './app/core/core.module';
import { reducers, metaReducers } from './app/core/core.reducers';
import { CoreEffects } from './app/core/core.effects';
import { WelcomeMessageModule } from './app/features/welcome-message/welcome-message.module';
import { AppComponent } from './app/app.component';

if (environment.key === 'Prod' || environment.key === 'Int') {
  enableProdMode();
}

if (environment.key === 'Local') {
  // Remove license messages for local dev only https://www.telerik.com/kendo-angular-ui/components/my-license#invalid-license
  const cg = console.group;

  console.group = (...args: unknown[]) => {
    const message = args[0];
    const isHasMessage = typeof message === 'string';
    if (isHasMessage && message.includes('Progress Kendo UI for Angular')) {
      return void 0;
    }

    cg(...args);
  }

  const cw = console.warn;

  console.warn = (...args: unknown[]) => {
    const message = args[0];
    const isHasMessage = typeof message === 'string';

    if (isHasMessage && message.includes('License activation failed')) {
      return void 0;
    }

    cw(...args);
  }
}

bootstrapApplication(AppComponent, {
    providers: [
      { provide: ICON_SETTINGS, useValue: { type: 'font' } },
      importProvidersFrom(
        SharedModule, AppRoutingModule, CoreModule, CoreModule.forRoot(), StoreModule.forRoot(reducers, {
            metaReducers,
            // TODO: as soon as we stabelize the version and cover with tests we can switch to use checks only in non-prod for performance reasons
            runtimeChecks: {
                strictStateSerializability: true,
                strictActionSerializability: true,
                strictActionTypeUniqueness: true,
                strictStateImmutability: true,
            },
        }), EffectsModule.forRoot([CoreEffects]), environment.key === 'Local'
            ? StoreDevtoolsModule.instrument()
            : [], NotificationModule, IconModule, PopupModule, WelcomeMessageModule),
    ],
})
  .catch((err) => console.error(err));
