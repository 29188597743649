import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { Analytics } from '@angular/fire/analytics';
import { AsyncPipe } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { DialogContainerDirective } from '@progress/kendo-angular-dialog';
import {defineCustomElements} from "@swisscom/sdx/dist/js/webcomponents/loader";
import {environment} from "../environments/environment";
import { selectIsAppInitFailed, selectIsAppLoading } from './core/core.selectors';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { loadClientConfig } from './core/core.actions';
import {TestIdDirective} from "./shared/directives/test-id.directive";

@Component({
    selector: 'aog-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
  imports: [
    LoadingComponent,
    RouterOutlet,
    DialogContainerDirective,
    AsyncPipe,
    TestIdDirective,
  ],
})
export class AppComponent implements OnInit {
    private readonly analytics: Analytics | null = inject(Analytics, { optional: true });
    public readonly isLoading$ = this.store.select(selectIsAppLoading);
    public readonly isLocal = environment.key === 'Local';

    public readonly initFailed$ = this.store.select(selectIsAppInitFailed);

    public constructor(
        private readonly store: Store,
    ) {
      defineCustomElements();
    }

    public ngOnInit(): void {
        this.initConfig();
        this.enableGoogleAnalytics();
    }

    private initConfig() {
        this.store.dispatch(loadClientConfig());
    }

    private enableGoogleAnalytics() {
        if (this.analytics) {
            this.analytics.app.automaticDataCollectionEnabled = true;
        }
    }
}
