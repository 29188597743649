import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {filter, map } from 'rxjs/operators';
import { ResourceStatus } from '../../shared/state/resource.state';
import { AOG_AUTH_SERVICE_INJECT_TOKEN, AuthService } from '../abstractions/auth.service';
import { selectUserState } from '../core.selectors';

@Injectable()
export class AuthGuard  {
    public constructor(
        @Inject(AOG_AUTH_SERVICE_INJECT_TOKEN)
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly store: Store,
    ) { }

    public canActivate(
        _route: ActivatedRouteSnapshot,
    ): Observable<boolean | UrlTree> {
        return this.store.select(selectUserState).pipe(
          filter((u) => u.status === ResourceStatus.failed || u.status === ResourceStatus.succeeded),
          map((u) => u?.data !== null && u?.data !== undefined && this.authService.hasValidTokens),
          map((canLoad) => (canLoad ? canLoad : this.router.parseUrl('/errors/unauthorized'))),
        );
    }
}
