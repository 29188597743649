import { ActionReducer } from "@ngrx/store";
import { logger } from "../utils/logging";

/**
 * Log all actions to console (for playwright, as Redux tools are not available there)
 */
export function debug<TState>(reducer: ActionReducer<TState>): ActionReducer<TState> {
    return function (state, action) {
        logger(() => action.type, 'Local');
        return reducer(state, action);
    };
}
