import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { OAuthModuleConfig, OAuthResourceServerConfig } from 'angular-oauth2-oidc';
import { selectClientConfig } from '../core.selectors';
import filterNullOrUndefined from '../operators/filterNullOrUndefined';

@Injectable()
export class AuthModuleConfigService implements OAuthModuleConfig, OnDestroy {

    private resourceServerConfig = {} as OAuthResourceServerConfig;

    private readonly configSubscrition = this.store
        .select(selectClientConfig)
        .pipe(filterNullOrUndefined())
        .subscribe((c) => this.resourceServerConfig = c.resourceServer);

    public constructor(private readonly store: Store) {
    }

    public ngOnDestroy(): void {
        this.configSubscrition.unsubscribe();
    }

    public get resourceServer(): OAuthResourceServerConfig {
        return this.resourceServerConfig;
    }
}
