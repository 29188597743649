import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { EnvironmentProviders, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ICON_SETTINGS } from '@progress/kendo-angular-icons';
import { ScreenTrackingService, provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { provideFirebaseApp, initializeApp, FirebaseOptions } from '@angular/fire/app';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { AuthGuard } from './guards/auth-guard.service';
import { throwIfAlreadyLoaded } from './guards/module-import-guard.service';
import { CorrelationIdHttpInterceptor } from './interceptors/correlation-id.interceptor';
import { ErrorHandlingInterceptor } from './interceptors/error-handling.interceptor';
import { RequestAuthenticationInterceptor } from './interceptors/request-authentication.interceptor';
import { RouteInfoSerializer } from './router/route-info.serializer';
import { serviceProviders } from './service-providers';
import {
  BACKEND_DATE_FORMAT_INJECT_TOKEN,
  DateFormatService,
  DISPLAY_DATE_FORMAT_INJECT_TOKEN,
} from './services/date-format.service';
import { HttpErrorEventDispatcherService } from './services/http-error-event-dispatcher.service';
import { UserService } from './services/user.service';
import { OidcRedirectUriEnrich } from './services/oidc-redirect-uri-enrich.service';
import { browserApiProviders } from './browser-api-providers';
import { AuthorizationService } from './services/authorization.service';
import { PermissionProviderService } from './services/permission-provider.service';
import { AOG_PERMISSION_PROVIDER_SERVICE_INJECT_TOKEN } from './abstractions/permission-provider';
import { ROUTER_STATE_KEY } from './core.state';

const firebaseConfig: FirebaseOptions = environment.firebaseConfig;

const googleApiModules: EnvironmentProviders[] = firebaseConfig.apiKey
  ? [
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
  ]
  : [];

const googleApiProviders = googleApiModules
  ? [
    ScreenTrackingService,
  ]
  : [];

@NgModule({ imports: [OAuthModule.forRoot(),
        StoreRouterConnectingModule.forRoot({
            stateKey: ROUTER_STATE_KEY,
            serializer: RouteInfoSerializer,
        }),
        BrowserAnimationsModule,
  ], providers: [
    ...googleApiModules,
    provideHttpClient(withInterceptorsFromDi()),
  ] })
export class CoreModule {
  public constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded<CoreModule>(parentModule, 'CoreModule');
  }

  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HttpErrorEventDispatcherService,
          useValue: new HttpErrorEventDispatcherService(),
        },
        // TODO: why we have Angular pipe here?
        DatePipe,
        DateFormatService,
        UserService,
        AuthorizationService,
        PermissionProviderService,
        AuthGuard,
        OidcRedirectUriEnrich,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestAuthenticationInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CorrelationIdHttpInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorHandlingInterceptor,
          multi: true,
        },
        // TODO: Decide what to use - localization service or environment and replace value
        {
          provide: DISPLAY_DATE_FORMAT_INJECT_TOKEN,
          useValue: 'dd.MM.yyyy',
        },
        // TODO: Decide what to use - localization service or environment and replace value
        {
          provide: BACKEND_DATE_FORMAT_INJECT_TOKEN,
          useValue: 'yyyy-MM-dd',
        },
        { provide: ICON_SETTINGS, useValue: { type: 'font', size: 'xlarge' } },
        {
          provide: AOG_PERMISSION_PROVIDER_SERVICE_INJECT_TOKEN,
          useClass: PermissionProviderService,
        },
        ...googleApiProviders,
        ...serviceProviders,
        ...browserApiProviders,
      ],
    };
  }
}
