import { KJUR, KEYUTIL } from 'jsrsasign';
import {
  AbstractValidationHandler,
  ValidationParams,
} from 'angular-oauth2-oidc';

interface Key {
  alg: string
  e: string
  kid: string
  kty: string
  n: string
  use: string
  value: string
}

/**
 * Validates the signature of an id_token against one
 * of the keys of an JSON Web Key Set (jwks).
 *
 * This is a fork of https://github.com/manfredsteyer/angular-oauth2-oidc/tree/master/projects/angular-oauth2-oidc-jwks
 * As the source is not maintained for a long time and has reference to the old Angular 9.
 * Also updated with the latest Typescript and jsrsasign
 */
export class JwksValidationService extends AbstractValidationHandler {
  /**
   * Allowed algorithms
   */
  private readonly allowedAlgorithms: string[] = [
    'HS256',
    'HS384',
    'HS512',
    'RS256',
    'RS384',
    'RS512',
    'ES256',
    'ES384',
    'PS256',
    'PS384',
    'PS512',
  ];

  /**
   * Time period in seconds the timestamp in the signature can
   * differ from the current time.
   */
  private readonly gracePeriodInSec = 600;

  public validateSignature(params: ValidationParams, retry = false): Promise<unknown> {
    return this.validateSignatureInternal(params, retry);
  }

  public calcHash(valueToHash: string, algorithm: string): Promise<string> {
    const digest = new KJUR.crypto.MessageDigest({ alg: algorithm });
    digest.digestString(valueToHash);
    const byteArrayAsString = this.toByteArrayAsString(valueToHash);
    return Promise.resolve(byteArrayAsString);
  }

  private assertValidationParams(params: ValidationParams): string {
    if (!params) {
      return 'Parameters empty or invalid!';
    }

    if (this.isNullOrEmpty(params.idToken)) {
      return 'Parameter idToken expected!';
    }

    const tokenHeader = params.idTokenHeader as { kid: string, alg: string }
    if (!tokenHeader
      || this.isNullOrEmpty(tokenHeader.kid)
      || this.isNullOrEmpty(tokenHeader.alg)) {
      return 'Invalid tokenHeader!';
    }

    const keysContainer = params.jwks as { keys: Key[] }

    if (!keysContainer) {
      return 'Invalid jwks!';
    }

    if (!keysContainer.keys || keysContainer.keys.length === 0) {
      return 'Array keys in jwks missing!';
    }

    return '';
  }

  private isNullOrEmpty(val: string): boolean {
    return !val || !val.trim();
  }

  private getKey(keys: Key[], keyId: string, alg: string): Key | undefined | Error {
    let key: Key | undefined;

    if (keyId) {
      key = keys.find((k) => k.kid === keyId);
    } else {
      const kty = this.alg2kty(alg);
      const matchingKeys = keys.filter(
        (k) => k.kty === kty && k.use === 'sig',
      );
      if (matchingKeys.length > 1) {
        return new Error('More than one matching key found. Please specify a kid in the id_token header.');
      } else if (matchingKeys.length === 1) {
        key = matchingKeys[0];
      }
    }

    return key;
  }

  private async validateSignatureInternal(params: ValidationParams, retry = false): Promise<string> {

    const inputError = this.assertValidationParams(params);
    if (inputError) {
      const error = new Error(inputError);
      return Promise.reject(error);
    }

    const keysContainer = params.jwks as { keys: Key[] }
    const keys = keysContainer.keys;

    const tokenHeader = params.idTokenHeader as { kid: string, alg: string }
    const keyId = tokenHeader.kid;
    const alg = tokenHeader.alg;

    const key = this.getKey(keys, keyId, alg);

    if (key instanceof Error) {
      return Promise.reject(key);
    }

    if (!key) {

      // Retry is available
      if (!retry && (await params.loadKeys())) {
        return this.validateSignatureInternal(params, true);
      }

      // Second attempt failed
      const errorMessage = keyId
        ? 'expected key not found in property jwks. ' +
        'This property is most likely loaded with the discovery document. ' +
        `Expected key id (kid): ${keyId}`
        : 'No matching key found.';

      const error = new Error(errorMessage);
      return Promise.reject(error);
    }

    const claims = params.idTokenClaims as { idp: string };

    if (claims && claims.idp === 'local') {
      return Promise.resolve('');
    }

    const keyObj = KEYUTIL.getKey(key.value);
    const pem = KEYUTIL.getPEM(keyObj);

    const validationOptions = {
      alg: this.allowedAlgorithms,
      gracePeriod: this.gracePeriodInSec,
    };

    const isValid = KJUR.jws.JWS.verifyJWT(
      params.idToken,
      pem,
      validationOptions,
    );

    if (isValid) {
      return Promise.resolve('');
    } else {
      return Promise.reject(new Error('Signature not valid'));
    }
  }

  private alg2kty(alg: string) {
    switch (alg.charAt(0)) {
      case 'R':
        return 'RSA';
      case 'E':
        return 'EC';
      default:
        throw new Error('Cannot infer kty from alg: ' + alg);
    }
  }

  private toByteArrayAsString(hexString: string) {
    let result = '';
    for (let i = 0; i < hexString.length; i += 2) {
      const hexDigit = hexString.charAt(i) + hexString.charAt(i + 1);
      const num = parseInt(hexDigit, 16);
      result += String.fromCharCode(num);
    }
    return result;
  }
}
