@if (isLoading$ | async) {
  @if (initFailed$ | async) {
    <!-- outlet for errors -->
    <router-outlet></router-outlet>
  } @else {
    <div class="center-spinner" aogTestId="bootstrap-init-loader">
      <aog-loading></aog-loading>
    </div>
  }
} @else {
  <div aogTestId="app-ready">
    <router-outlet></router-outlet>
  </div>
}

@if (isLocal) {
  <!--  Remove kendo-ui watermarks for local dev only https://www.telerik.com/kendo-angular-ui/components/my-license#invalid-license -->
  <style>
    /* Notification top-right corner */
    ::ng-deep div[kendowatermarkoverlay] {
    opacity: 0 !important;
  }

  /* Notification top-right corner */
  ::ng-deep body>div[style="display: flex; justify-content: center; align-items: center; position: fixed; top: 16px; right: 16px; padding: 12px; border-radius: 4px; box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 5px 0px, rgba(0, 0, 0, 0.03) 0px 2px 4px 0px; font-size: 14px; font-weight: 400; line-height: 20px; background-color: rgb(255, 192, 0); color: rgb(30, 30, 30); z-index: 20000;"] {
  display: none !important;
}
</style>
}
