import { routerReducer } from '@ngrx/router-store';
import { Action, ActionReducerMap, createReducer, MetaReducer, on } from '@ngrx/store';
import { ResourceStatus, updateFromErrorResponse } from '../shared/state/resource.state';

import { environment } from '../../environments/environment';
import {
  loadClientConfig,
  failConfigurationLoading,
  loadClientConfigSucceeded,
  faileUserDataRequest,
  loadUserDataSucceeded,
  failLogin,
  addNotification,
  removeNotification,
  loadFeatureManager,
  loadFeatureManagerSucceeded,
  updatePeopleGroupAuthorization,
} from './core.actions';
import { AppState, initialAppState, RootState } from './core.state';
import { peopleGroupAuthorizationAdapter } from './people-group-authorization.state';
import { debug } from './meta-reducers/debug-log-actions.reducers';

const reducer = createReducer(
  initialAppState,
  on(
    loadClientConfig,
    (state): AppState => ({
      ...state,
      clientConfig: {
        ...state.clientConfig,
        status: ResourceStatus.pending,
        error: null,
      },
    }),
  ),

  on(
    loadClientConfigSucceeded,
    (state, { config }): AppState => ({
      ...state,
      clientConfig: {
        ...state.clientConfig,
        status: ResourceStatus.succeeded,
        data: {
          ...config,
        },
        error: null,
      },
    }),
  ),

  on(
    failConfigurationLoading,
    (state, { error }): AppState => ({
      ...state,
      clientConfig: updateFromErrorResponse(state.clientConfig, error),
    }),
  ),

  on(loadFeatureManager, (state): AppState => ({
    ...state,
    featureManager: {
      ...state.featureManager,
      status: ResourceStatus.pending,
      error: null,
    },
  })),

  on(loadFeatureManagerSucceeded, (state): AppState => ({
    ...state,
    featureManager: {
      status: ResourceStatus.succeeded,
      data: true,
      error: null,
    },
  })),

  on(
    loadUserDataSucceeded,
    (state, { user }): AppState => ({
      ...state,
      user: {
        ...state.user,
        status: ResourceStatus.succeeded,
        data: {
          ...user,
        },
        error: null,
      },
    }),
  ),

  on(
    faileUserDataRequest,
    (state, { error }): AppState => ({
      ...state,
      user: updateFromErrorResponse(state.user, error),
    }),
  ),

  on(
    failLogin,
    (state): AppState => ({
      ...state,
      user: {
        ...state.user,
        status: ResourceStatus.failed,
      },
    }),
  ),

  on(
    addNotification,
    (state, { notification }): AppState => ({
      ...state,
      notifications: [...state.notifications, notification],
    }),
  ),

  on(
    removeNotification,
    (state, { id }): AppState => ({
      ...state,
      notifications: state.notifications.filter((n) => n.id !== id),
    }),
  ),

  on(updatePeopleGroupAuthorization, (state, { authorization }): AppState => ({
    ...state,
    peopleGroupAuthorization: peopleGroupAuthorizationAdapter.upsertOne(authorization, state.peopleGroupAuthorization),
  })),
);

export function appReducer(state: AppState | undefined, action: Action): AppState {
  return reducer(state, action);
}

export const reducers: ActionReducerMap<RootState> = {
  app: appReducer,
  router: routerReducer,
};

export const metaReducers: MetaReducer<RootState>[] = environment.key === 'Local' ? [debug<RootState>] : [];
